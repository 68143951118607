import './Home.css';

import React from 'react';
import HomeList from "./HomeLijst";

const Home = () => {
    return (
        <div className="home">
            <div className="content-container container-sm">
                <HomeList/>
            </div>
        </div>
    );
};

export default Home;
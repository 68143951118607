import './Fotos.css';

import React from 'react';
import FotosList from "../Fotos/FotosLijst";

const Fotos = () => {
    return (
        <div className="content-container container-sm">
            <h2>Foto's</h2>
            <FotosList/>
        </div>  );
};

export default Fotos;
import React, {useEffect, useState} from 'react';
import {client, urlFor} from '../sanityURLbuilder';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import {animated, useSpring} from 'react-spring';

const FotosList = () => {
    const [info, setFoto] = useState([]);
    const orderedFoto = info.sort((a, b) => a.order - b.order);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        client
            .fetch('*[_type == "fotoInformatie"]')
            .then((data) => {
                setFoto(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);

                setLoading(false);
            });
    }, []);

    const getGridClass = (numberOfImages, index) => {
        if (numberOfImages === 1) {
            return 'col-md-12';
        } else if (numberOfImages === 2 || numberOfImages === 4 || numberOfImages === 6) {
            return 'col-md-6';
        } else if ((numberOfImages === 5 && index === numberOfImages - 1) || (numberOfImages === 3 && index === numberOfImages - 1)) {
            return 'offset-md-3 col-md-6';
        }

        return 'col-md-6';
    };

    const handleImageClick = (index) => {
        if (!lightboxOpen) {
            setLightboxIndex(index);
            setLightboxOpen(true);
        }
    };

    const handleCloseLightbox = () => {
        setLightboxOpen(false);
    };

    const lightboxImages = orderedFoto.flatMap((fotoItem) =>
        fotoItem.media?.map((mediaItem) => urlFor(mediaItem.afbeelding).url())
    );
    const fadeInAndGrow = useSpring({
        opacity: 1,
        transform: 'scale(1)',
        from: {opacity: 0, transform: 'scale(0.9)'},
        config: {duration: 500},
    });

    return (
        <div>
            {loading ? (
                <div className="custom-spinner-container">
                    <div className="custom-spinner">
                        <span className="sr-only loading-text">Laden...</span>
                    </div>
                </div>
            ) : (
                <div className="container">
                    <div className="row">
                        {orderedFoto.map((fotoItem) => (
                            <animated.div
                                key={fotoItem._id}
                                className={`foto-item`}
                                style={fadeInAndGrow}
                            >
                                <div className="card fotoCard mb-4">
                                    <div className="card-body">
                                        <h2 className="card-title">{fotoItem.titel}</h2>
                                        <div className="row">
                                            {fotoItem.media &&
                                                fotoItem.media.map((mediaItem, index) => (
                                                    <div key={index} className={`${getGridClass(fotoItem.media.length, index)} mb-4`}>
                                                        <div className="media-section" style={{ marginBottom: '1rem' }}>
                                                            {mediaItem.afbeelding && (
                                                                <img
                                                                    src={urlFor(mediaItem.afbeelding).url()}
                                                                    alt={`${mediaItem.alt}`}
                                                                    className="img-fluid img-thumbnail"
                                                                    onClick={() => handleImageClick(lightboxImages.indexOf(urlFor(mediaItem.afbeelding).url()))}
                                                                />
                                                            )}
                                                            {mediaItem.tekst && <p className="card-text">{mediaItem.tekst}</p>}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </animated.div>
                        ))}
                    </div>
                    {lightboxOpen && (
                        <Lightbox
                            mainSrc={lightboxImages[lightboxIndex]}
                            nextSrc={lightboxImages[(lightboxIndex + 1) % lightboxImages.length]}
                            prevSrc={lightboxImages[(lightboxIndex + lightboxImages.length - 1) % lightboxImages.length]}
                            onCloseRequest={handleCloseLightbox}
                            onMovePrevRequest={() => setLightboxIndex((lightboxIndex + lightboxImages.length - 1) % lightboxImages.length)}
                            onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % lightboxImages.length)}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
export default FotosList;

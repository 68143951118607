import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Layout from './components/Layout/Layout';
import Home from './components/Home/Home';
import Appartement from './components/Appartement/Appartement';
import Fotos from './components/Fotos/Fotos';
import Prijzen from './components/Prijzen/Prijzen';
import Bezienswaardigheden from './components/Bezienswaardigheden/Bezienswaardigheden';
import Contact from './components/Contact/Contact';

const App = () => {
    return (
        <div>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-04QPWRPD2J"></script>
                <script>{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'G-04QPWRPD2J');
                    `}
                </script>
            </Helmet>
            <BrowserRouter forceRefresh={true}>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/appartement" element={<Appartement/>}/>
                        <Route path="/fotos" element={<Fotos/>}/>
                        <Route path="/prijzen" element={<Prijzen/>}/>
                        <Route path="/bezienswaardigheden" element={<Bezienswaardigheden/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </div>

    );
};

export default App;

import './Prijzen.css';

import React from 'react';
import PrijzenList from "./PrijzenLijst";

const Prijzen = () => {
    return (
        <div className="content-container container-sm">
            <h2>Prijzen</h2>
            <PrijzenList/>
        </div>  );
};

export default Prijzen;
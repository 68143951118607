import imageUrlBuilder from '@sanity/image-url'

import {createClient} from '@sanity/client'

export const client = createClient({
    projectId: 'nv57ghv3',
    dataset: 'production',
    useCdn: true,
    apiVersion: '2023-10-30',
})


const builder = imageUrlBuilder(client)

export function urlFor(source) {
    return builder.image(source)
}
import React, {useEffect, useState} from 'react';
import {client} from '../sanityURLbuilder';


const PrijzenList = () => {
    const [prijzen, setPrijzen] = useState([]);
    const orderedPrijzen = prijzen.sort((a, b) => a.order - b.order);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        client
            .fetch('*[_type == "accommodatieprijzen"]')
            .then((data) => {
                setPrijzen(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);

                setLoading(false);
            });
    }, []);


    return (
        <div>
            {loading ? (
                <div className="custom-spinner-container">
                    <div className="custom-spinner">
                        <span className="sr-only loading-text">Laden...</span>
                    </div>
                </div>
            ) : (
                <div className="row">
                    {orderedPrijzen.map((prijzenItem) => (
                        <div key={prijzenItem._id} className="prijzen-item">
                            <p>
                                {prijzenItem.prijsvooriets && (
                                    <React.Fragment>
                                        {prijzenItem.prijsvooriets}
                                        {prijzenItem.extrainfo && prijzenItem.extrainfo.verplicht && prijzenItem.extrainfo.info ? ' (verplicht)' : ''}
                                    </React.Fragment>
                                )}
                            </p>
                            {prijzenItem.extrainfo && (
                                <p className="indented">{prijzenItem.extrainfo.info}</p>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
export default PrijzenList;

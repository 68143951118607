import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { client, urlFor } from '../sanityURLbuilder';
import TextBlockWithMarks from "../TextBlockwithMarks";

import '../slick-carousel/slick.css';
import '../slick-carousel/slick-theme.css';

const BezienswaardigheidList = () => {
    const [bezienswaardigheden, setBezienswaardigheden] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        client
            .fetch('*[_type == "bezienswaardigheid"]')
            .then((data) => {
                setBezienswaardigheden(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <button className="slick-prev">Previous</button>,
        nextArrow: <button className="slick-next">Next</button>,
    };

    return (
        <div>
            {loading ? (
                <div className="custom-spinner-container">
                    <div className="custom-spinner">
                        <span className="sr-only loading-text">Laden...</span>
                    </div>
                </div>
            ) : (
                <div className="row">
                    {bezienswaardigheden.map((bezienswaardigheid) => (
                        <div key={bezienswaardigheid._id} className="col-sm-12 col-md-6 col-lg-6 mb-4">
                            <div className="card bezienswaardigheidscard">
                                {bezienswaardigheid.foto && bezienswaardigheid.foto.length > 0 && (
                                    <Slider {...settings}>
                                        {bezienswaardigheid.foto && bezienswaardigheid.foto.map((photo, index) => (
                                            <div key={index}>
                                                <img
                                                    src={urlFor(photo).url()}
                                                    alt={photo.alt}
                                                    className="card-img-top"
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                )}
                                <div className="card-body">
                                    <h5 className="card-title">{bezienswaardigheid.titel || "Geen titel beschikbaar"}</h5>
                                    {bezienswaardigheid.beschrijving && bezienswaardigheid.beschrijving.length > 0 ? (
                                        <div>
                                            {bezienswaardigheid.beschrijving.map((block, blockIndex) => (
                                                <React.Fragment key={blockIndex}>
                                                    {block.style === 'normal' && <TextBlockWithMarks block={block}/>}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    ) : (
                                        <span>Geen tekst beschikbaar</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default BezienswaardigheidList;
import React from 'react';
import './Contact.css';
import {useForm, ValidationError} from '@formspree/react';

const FormspreeForm = () => {
    const [state, handleSubmit] = useForm("mqkvnvjy");
    if (state.succeeded) {
        return <div className="contact-form col-lg-6 col-sm-12"><h2>Bedankt voor je bericht!</h2></div>;
    }
/*
    const LocalValidation = () => {
        const naam = document.getElementById('naam').value
        const email = document.getElementById('email').value
        const onderwerp = document.getElementById('onderwerp').value
        const bericht = document.getElementById('bericht').value

        if (!naam || !email || !onderwerp || !bericht) {
            alert('Vul alstublieft alle velden in.');
            return;
        }

        if (naam.length < 2) {
            alert('Naam moet minimaal 2 tekens lang zijn.');
            return;
        }

        if (naam.length > 75) {
            alert('Naam mag maximaal 75 tekens lang zijn.');
            return;
        }

        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailPattern.test(email)) {
            alert('Ongeldig e-mailadres.');
            return;
        }

        if (onderwerp.length < 5) {
            alert('Onderwerp moet minimaal 5 tekens lang zijn.');
            return;
        }

        if (onderwerp.length > 100) {
            alert('Onderwerp mag maximaal 100 tekens lang zijn.');
            return;
        }

        if (bericht.length < 10) {
            alert('Bericht moet minimaal 10 tekens lang zijn.');
            return;
        }

        if (bericht.length > 1000) {
            alert('Bericht mag maximaal 1000 tekens lang zijn.');
            return;
        }
    };
*/
    return (
        <div className="contact-form col-lg-6 col-sm-12">
            <h2>Neem contact op:</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="naam" className="form-label">
                        Naam:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="naam"
                        name="naam"
                        required
                    />
                </div>
                <ValidationError
                    prefix="Naam"
                    field="naam"
                    errors={state.errors}
                />
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                        Email Adres:
                    </label>
                    <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        required
                    />
                </div>
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                />
                <div className="mb-3">
                    <label htmlFor="onderwerp" className="form-label">
                        Onderwerp:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="onderwerp"
                        name="onderwerp"
                        required
                    />
                </div>
                <ValidationError
                    prefix="Onderwerp"
                    field="onderwerp"
                    errors={state.errors}
                />
                <div className="mb-3">
                    <label htmlFor="bericht" className="form-label">
                        Bericht:
                    </label>
                    <textarea
                        className="form-control"
                        id="bericht"
                        name="bericht"
                        rows="4"
                        required
                    />
                </div>
                <ValidationError
                    prefix="Bericht"
                    field="bericht"
                    errors={state.errors}
                />
                <button type="submit" className="btn btn-primary" disabled={state.submitting}>
                    Verstuur
                </button>
            </form>
        </div>
    );
}

const Contact = () => {
    return (
        <div className="contact-container container-sm row ">
            <FormspreeForm/>
            <div className="contact-map col-lg-6 col-sm-12">
                <iframe className="map"
                        title="Google Maps"
                        src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d7044.061967694491!2d-16.699374773814093!3d28.02352881617925!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDAxJzI1LjAiTiAxNsKwNDEnMzkuOCJX!5e0!3m2!1sen!2sbe!4v1702828577630!5m2!1sen!2sbe"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                />

            </div>
        </div>
    );
}


export default Contact;

/*
const Contact = () => {
    const handleFormSubmit = (e) => {
        e.preventDefault();

        const naam = document.getElementById('naam').value
        const email = document.getElementById('email').value
        const onderwerp = document.getElementById('onderwerp').value
        const bericht = document.getElementById('bericht').value

        if (!naam || !email || !onderwerp || !bericht) {
            alert('Vul alstublieft alle velden in.');
            return;
        }

        if (naam.length < 2) {
            alert('Naam moet minimaal 2 tekens lang zijn.');
            return;
        }

        if (naam.length > 75) {
            alert('Naam mag maximaal 75 tekens lang zijn.');
            return;
        }

        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailPattern.test(email)) {
            alert('Ongeldig e-mailadres.');
            return;
        }

        if (onderwerp.length < 5) {
            alert('Onderwerp moet minimaal 5 tekens lang zijn.');
            return;
        }

        if (onderwerp.length > 100) {
            alert('Onderwerp mag maximaal 100 tekens lang zijn.');
            return;
        }

        if (bericht.length < 10) {
            alert('Bericht moet minimaal 10 tekens lang zijn.');
            return;
        }

        if (bericht.length > 1000) {
            alert('Bericht mag maximaal 1000 tekens lang zijn.');
            return;
        }

        const body = `naam: ${naam}\nbericht: ${bericht}`
        sendEmail(email, onderwerp, body);
    };


    const sendEmail = (email, subject, body) => {
        const mailOptions = {
            to: 'cantbryan@gmail.com',
            from: email,
            subject: subject,
            text: body,
        };


    };
*/


import React, {useEffect, useState} from 'react';
import {client, urlFor} from '../sanityURLbuilder';
import TextBlockWithMarks from '../TextBlockwithMarks';



const HomeList = () => {
    const [home, setHome] = useState([]);
    const [loading, setLoading] = useState(true);
    const orderedHome = home.sort((a, b) => a.order - b.order);

    useEffect(() => {
        setLoading(true);

        client
            .fetch('*[_type == "homeSection"]')
            .then((data) => {
                setHome(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    return (
        <div>
            {loading ? (
                <div style={{marginTop: 1 + 'em'}} className="custom-spinner-container">
                    <div className="custom-spinner">
                        <span className="sr-only loading-text">Laden...</span>
                    </div>
                </div>
            ) : (
                orderedHome.map((section) => (
                    <div key={section._id} className="section row">
                        <div
                            className={`img-section col-lg-6 col-sm-12 ${section.imagePosition ? 'order-2' : 'order-1'}`}>
                            <img className="image-edit" src={urlFor(section.image).url()}
                                 alt={section.imageCaption || 'Afbeelding'}/>
                            {section.imageCaption && (
                                <div className="text-container">
                                    <p>{section.imageCaption}</p>
                                </div>
                            )}
                        </div>
                        <div
                            className={`text-section col-lg-6 col-sm-12 ${section.imagePosition ? 'order-1' : 'order-2'}`}>
                            {section.showTitle && <h2>{section.title}</h2>}
                            <div>
                                {section.text && section.text.length > 0 ? (
                                    section.text.map((block, blockIndex) => (
                                        <React.Fragment key={blockIndex}>
                                            {block.style === 'normal' && <TextBlockWithMarks block={block}/>}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    // Handle the case where section.text is undefined or empty
                                    <span>No text available</span>
                                )}
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};
export default HomeList;

import React from 'react';
import {Link} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './Navigation.css';
import WebsiteLogo from '../../assets/images/Wonders of pal mar cropped.png';

const Navigation = () => {
    return (
        <div>
            <Navbar className="container-sm" expand="lg" bg="light">
                <Navbar.Brand>
                    <Link to="/">
                        <img src={WebsiteLogo} alt="Logo" className="img-fluid"/>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Item>
                            <Link to="/" className="nav-link">Home</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/appartement" className="nav-link">Appartement</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/fotos" className="nav-link">Foto's</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/prijzen" className="nav-link">Prijzen</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/bezienswaardigheden" className="nav-link">Bezienswaardigheden</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/contact" className="nav-link">Contact</Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>

            </Navbar>
            <div className="ocean-wave"></div>
        </div>
    );
};

export default Navigation
/*<nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-between">
            <div>
                <a className="logo navbar-brand" href="#"><img src="" alt="Logo"/></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="nav-list navbar-nav navbar-right">
                    <li className="nav-item active">
                        <Link to="/" className="nav-link">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/appartement" className="nav-link">Appartement</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/fotos" className="nav-link">Foto's</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/prijzen" className="nav-link">Prijzen</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/bezienswaardigheden" className="nav-link">Bezienswaardigheden</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contact" className="nav-link">Contact</Link>
                    </li>
                </ul>
            </div>
        </nav>*/
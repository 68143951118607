import './Appartement.css';

import React from 'react';
import AppartementList from "../Appartement/AppartementLijst";

const Appartement = () => {
    return (
        <div className="content-container container-sm">
            <h2>Het appartement</h2>
            <AppartementList/>
        </div>  );
};

export default Appartement;
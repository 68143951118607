import React from 'react';
import { Helmet } from 'react-helmet';
import './Layout.css';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';

const Layout = ({children}) => {
    return (

        <div>
            <Helmet>
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@400;700&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <Navigation/>
            <main>
                {children}
            </main>
            <Footer/>
        </div>
    );
};

export default Layout;
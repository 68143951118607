import React, {useEffect, useState} from 'react';
import {client, urlFor} from '../sanityURLbuilder';


const AppartementList = () => {
    const [info, setInfo] = useState([]);
    const orderedInfo = info.sort((a, b) => a.order - b.order);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        client
            .fetch('*[_type == "appartementInformatie"]')
            .then((data) => {
                setInfo(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);

                setLoading(false);
            });
    }, []);

    return (
        <div>
            {loading ? (
                <div className="custom-spinner-container">
                    <div className="custom-spinner">
                        <span className="sr-only loading-text">Laden...</span>
                    </div>
                </div>
            ) : (
                <div className="row">
                    {orderedInfo.map((infoItem) => (
                        <div key={infoItem._id} className="appartement-item">
                            {infoItem.showTitle && <h4>{infoItem.titel}</h4>}
                            <div className="infoItem">
                                <p>{infoItem.inhoud}</p>
                                {infoItem.afbeelding && (
                                    <img src={urlFor(infoItem.afbeelding).url()} alt="Appartement"/>
                                )}
                            </div>


                            {infoItem.paddingNaInhoud && <div className="padding-after-content"/>}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AppartementList;

import React from "react";

const TextBlockWithMarks = ({ block }) => {
    return (
        <p>
            {block.children.map((child, childIndex) => {
                const markKey = child.marks && child.marks.length > 0 ? child.marks[0] : null;

                if (markKey) {
                    const markDef = block.markDefs.find(mark => mark._key === markKey);
                    const markType = markDef ? markDef._type : null;

                    return (
                        <React.Fragment key={childIndex}>
                            {child.marks[0] === 'strong' ? (
                                <strong>{child.text}</strong>
                            ) : child.marks[0] === 'em' ? (
                                <em>{child.text}</em>
                            ) : child.marks[0] === 'code' ? (
                                <code>{child.text}</code>
                            ) : child.marks[0] === 'underline' ? (
                                <u>{child.text}</u>
                            ) : child.marks[0] === 'bullet-list' ? (
                                <li>{child.text}</li>
                            ) : child.marks[0] === 'numbered-list' ? (
                                <li>{child.text}</li>
                            ) : markType === 'link' ? (
                                <a href={markDef.href} target="_blank" rel="noopener noreferrer">
                                    {child.text}
                                </a>
                            ) : (
                                // Handle additional marks as needed
                                console.log('Unknown mark type', markType),
                                    <span>{child.text} </span>
                            )}
                        </React.Fragment>
                    );
                } else {
                    return <span key={childIndex}>{child.text} </span>;
                }
            })}
        </p>
    );
};

export default TextBlockWithMarks;
import React from 'react';
import './Footer.css';
const currentYear = new Date().getFullYear();

const Footer = () => {
    return (
        <footer className="footer">
            <div className="centered-content">
                Copyright © {currentYear} <a href="https://www.MaravillasDePalmMar.be">www.MaravillasDePalmMar.be</a>
            </div>
        </footer>
    );
};

export default Footer;
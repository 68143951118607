import './Bezienswaardigheden.css';
import BezienswaardigheidList from './BezienswaardigheidLijst';
import React from 'react';

const Bezienswaardigheden = () => {
    return (
        <div className="content-container container-sm">
            <h2>Bezienswaardigheden</h2>
            <BezienswaardigheidList/>
    </div>  );
};

export default Bezienswaardigheden;